import React from "react";
import LayOut from "@components/layout";
import { Container } from "@util/standard";

import styled from "styled-components";
import { TABLET_BREAKPOINT } from "@util/constants";

const Styledh1 = styled.h1`
  font-family: "header";
  font-size: 100px;

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    text-align: center;
  }
`;

const NotFound = () => {
  // const { sanityNotFound } = useNotFoundQuery();

  return (
    <>
      <LayOut>
        <Container
          width="80%"
          margin="100px auto"
          flexDirection="row"
          display="flex"
          justifyContent="space-around"
          isMobileColumn
        >
          <Container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width="250px"
            mobileMargin="0px auto 40px"
            tabletMargin="0px auto 40px"
          >
            <Styledh1>404</Styledh1>
            {/* <BlocksContent blocks={sanityNotFound?.title?._rawContent} /> */}
          </Container>
          {/* {sanityNotFound?.image && (
            <Container height="500px" width="50%" mobileWidth="80%">
              <StyledImg data={sanityNotFound?.image} />
            </Container>
          )} */}
        </Container>
      </LayOut>
    </>
  );
};

export default NotFound;
